import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import "./stacking.min.css";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { abi } from "./ABI/StakingABI.json";
import { tokenAbi } from "./ABI/TokenABI.json";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
import maintenance from "../../assets/gifs/newAnimation.json";
import "./stacking.css";

const useStyles = makeStyles((theme: any) => {
  return {
    stakingWrapper: {
      display: "flex",
      position: "relative",
      marginTop: "160px",
      paddingBottom: "160px",
    },
    stakingImageWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      "& img": {
        [theme.breakpoints.between("320", "1200")]: {
          maxWidth: "90%",
          height: "auto",
        },
      },
    },
    stakingCard: {
      maxWidth: "38rem",
      width: "auto",
      background: theme.colors.primary,
      borderRadius: "100px 0px 0px 100px",
      padding: "110px 80px 80px 89px",
      float: "right",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        padding: "55px 40px 40px 44px",
        float: "none",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "55px 25px 40px 25px",
        margin: "auto",
        width: "80%",
        borderRadius: "50px",
        float: "none",
      },

      "& h1": {
        fontStyle: "normal",
        margin: 0,
        fontWeight: 700,
        fontSize: "60px",
        lineHeight: "90px",
        color: theme.colors.white,
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
          lineHeight: "30px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "40px",
          lineHeight: "40px",
        },
      },
      "& p": {
        color: theme.colors.white,
        fontWeight: 400,
        fontSize: "1.25rem",
        lineHeight: "23px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.6rem",
          lineHeight: "15px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "0.9rem",
          lineHeight: "19px",
        },
      },
      "& p:nth-child(2)": { marginTop: "4.06rem" },
      "& p:nth-child(3)": { marginTop: "2.81rem" },
    },
    note: {
      marginTop: "4.06rem",
      display: "flex",
    },
    noteItem: {
      background: theme.colors.darkBlue,
      color: theme.colors.white,
      padding: "10px 20px",
      borderRadius: "30px",
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        padding: "10px 15px",
        fontSize: "12px",
      },
    },
  };
});

function LinearProgressWithLabel(props: { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 38 }}>
        <Typography variant="body2" color="text.secondary"></Typography>
      </Box>
    </Box>
  );
}

export default function Staking() {
  const classes: any = useStyles();
  const history = useHistory();
  const [key, setKey] = useState<string>("30days");
  const [progress, setProgress] = React.useState(0);

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: process.env.REACT_APP_INFURA_PROJECT_ID_STAKE,
      },
    },
  };

  const web3Modal = new Web3Modal({
    network: "mumbai",
    cacheProvider: true,
    providerOptions,
  });

  interface Stake {
    totalAmount: string;
    days: number;
    totalRewards: string;
    isRevoked: boolean;
    isClaimed: boolean;
  }

  interface EthereumError extends Error {
    code?: number | string;
    message: string;
    action?: string;
    transaction?: {
      data: string;
      to: string;
      from: string;
      gasLimit?: {
        type: string;
        hex: string;
      };
    };
  }
  const [selectedKey, setSelectedKey] = useState("30days");
  const [isWalletConnected, setIsWalletConnected] = useState<boolean>(true);
  const [rewardPercentage, setRewardPercentage] = useState<number>(15);
  const [reward, setReward] = useState<number>(0);
  const [web3Signer, setWeb3Signer] = useState<string>("");
  const [selectedDays, setSelectedDays] = useState<number>(30);
  const [stakeAmount, setStakeAmount] = useState<any>();
  const [daysLeft, setDaysLeft] = useState<number>(0);
  const [lockedToken, setLockedToken] = useState<number>(0);
  const [lockedDuration, setLockedDuration] = useState<number>(0);
  const [totalReward, setTotalReward] = useState<number>(0);
  const [stakingLoader, setStakingLoader] = useState<boolean>(false);
  const [rewardLoader, setRewardLoader] = useState<boolean>(false);
  const [unStakeLoader, setUnStakeLoader] = useState<boolean>(false);
  const [unStakeID, setUnStakeID] = useState<any>(null);
  const [activeStakes, setActiveStakes] = useState<Stake[]>();
  const [rawStakes, setRawStakes] = useState<any>();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const resetConnectionState = () => {
    setIsWalletConnected(false);
    localStorage.removeItem("isWalletConnected");
  };

  // Function to handle account changes
  const handleAccountsChanged = async (accounts: string | any[]) => {
    if (accounts.length === 0) {
      resetConnectionState();
    } else {
      connectWallet();
      setIsWalletConnected(true);
      localStorage.setItem("isWalletConnected", "true");
    }
  };

  const handleChainChanged = (chainId: string) => {
    resetConnectionState();
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, []);

  const handleStakeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (!value || value.match(/^\d*\.?\d*$/)) {
      setStakeAmount(Number(value));
      calculateReward();
    }
  };

  useEffect(() => {
    calculateReward();
  }, [stakeAmount, selectedDays]);

  useEffect(() => {
    async function checkWalletAndFetchData() {
      const isWalletConnected = localStorage.getItem("isWalletConnected");
      setIsWalletConnected(Boolean(isWalletConnected));
      // if (Boolean(isWalletConnected) !== true) {
      try {
        // const provider = await web3Modal.connect();
        const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = web3Provider.getSigner();
        const temp = await signer.getAddress();

        setWeb3Signer(temp);
        getData(signer);
      } catch (error) {
        console.error("Error connecting to wallet or fetching data:", error);
        // toast.error("Error connecting to the wallet");
      }
    }
    // }

    checkWalletAndFetchData();
  }, []);

  //   function calculateFutureValueCompound(uint256 presentValue, uint256 _days) public view returns (uint256) {
  //     uint256 reward = presentValue * APY * 100; // convert decimal APY to fixed-point arithmetic
  //     uint256 oneDay = reward / 365;
  //     uint256 temp = (oneDay * _days) / 10000;
  //     uint256 futureValue = presentValue + temp ;
  //     return futureValue;
  // }

  const calculateReward = () => {
    const totalReward = stakeAmount * (rewardPercentage / 100); // convert decimal APY to fixed-point arithmetic
    const oneDayReward = totalReward / 365;
    const rewardTokens = stakeAmount + oneDayReward * selectedDays;
    setReward(rewardTokens);
  };

  const displayValue = (value: any) => (isWalletConnected ? value : "***");

  const handleSelect = (k: string | null) => {
    if (k) {
      switch (k) {
        case "30days":
          setSelectedDays(30);
          setRewardPercentage(15);
          setSelectedKey("30days");
          break;
        case "90days":
          setSelectedDays(90);
          setRewardPercentage(20);
          setSelectedKey("90days");
          break;
        case "180days":
          setSelectedDays(180);
          setRewardPercentage(25);
          setSelectedKey("180days");
          break;
        case "365days":
          setSelectedDays(365);
          setRewardPercentage(25);
          setSelectedKey("365days");
          break;
        default:
          setSelectedDays(0);
          setSelectedKey("default");
      }
    }
  };

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);

      // Get the user's address
      const signer = web3Provider.getSigner();
      const address = await signer.getAddress();
      setIsWalletConnected(true);
      localStorage.setItem("isWalletConnected", "true");
      getData(signer);

      const { chainId } = await web3Provider.getNetwork();

      if (chainId !== Number(process.env.REACT_APP_CHAIN_ID)) {
        // 80001 is the chain ID for Polygon Mumbai
        try {
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: process.env.REACT_APP_CHAIN_ID_HEX }], // Hexadecimal version of the chainId
          });
        } catch (switchError: any) {
          // This error code indicates that the chain has not been added to MetaMask
          if (switchError.code === 4902) {
            try {
              await provider.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: process.env.REACT_APP_CHAIN_ID_HEX,
                    chainName: "Polygon Mumbai",
                    rpcUrls: [process.env.REACT_APP_RPC_URL],
                    blockExplorerUrls: [process.env.REACT_APP_EXPLORER_URL],
                    nativeCurrency: {
                      name: "MATIC",
                      symbol: "MATIC",
                      decimals: 18,
                    },
                  },
                ],
              });
            } catch (addError) {
              console.error("Failed to add the network", addError);
              toast.error("Failed to add the network");
            }
          } else {
            console.error("Failed to switch to the network", switchError);
            toast.error("Failed to switch to the network");
          }
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const checkBalanceAndStake = async (
    address: string,
    tokenContract: ethers.Contract
  ) => {
    try {
      const balance = await tokenContract.balanceOf(address);
      const minStakeAmount = ethers.utils.parseUnits("1000", "ether");

      if (balance.isZero()) {
        toast.error("You do not have any $IDEA tokens in your account");
        return false;
      } else if (balance.lt(minStakeAmount)) {
        toast.error(
          "You cannot stake, if your token balance is below 1000 $IDEA"
        );
        return false;
      } else if (
        balance.lt(ethers.utils.parseUnits(String(stakeAmount), "ether"))
      ) {
        toast.error("You do not have enough tokens in your wallet to stake");
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error fetching the balance of the user:", error);
      toast.error("Error fetching the balance of the user");
      return false;
    }
  };
  const handleRadioChange = (stakeId: number) => {
    setUnStakeID((prev: any) => {
      return stakeId; // returning the new value to update the state
    });
    progressBar(stakeId);
  };
  const createStake = async () => {
    try {
      const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = web3Provider.getSigner();
      const address = await signer.getAddress();

      const stakingContract = new ethers.Contract(
        String(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS),
        abi,
        signer
      );

      const tokenContract = new ethers.Contract(
        String(process.env.REACT_APP_TOKEN_CONTRACT),
        tokenAbi,
        signer
      );

      if (stakeAmount <= 0) {
        toast.error("Please enter a valid stake amount");
        return;
      }

      if (!(await checkBalanceAndStake(address, tokenContract))) {
        return;
      }

      try {
        setStakingLoader(true);

        const allowance = await tokenContract.allowance(
          address,
          String(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS)
          // ethers.utils.parseUnits(String(stakeAmount), "ether")
        );
        // if (!allowance.gt(stakeAmount)) {
        const approvalTx = await tokenContract.approve(
          String(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS),
          ethers.utils.parseUnits(String(stakeAmount), "ether")
        );
        await approvalTx.wait();
        // }
      } catch (error) {
        setStakingLoader(false);
        toast.error("Connect Metamask!");

        handleTransactionError(error);
        return;
      }

      const stakingTx = await stakingContract.stakeToken(
        ethers.utils.parseUnits(String(stakeAmount), "ether"),
        selectedDays
      );

      await stakingTx.wait();
      // window.location.reload();
      setStakingLoader(false);
      setStakeAmount(0);
      getData(signer);
      toast.success("Stake created successfully!");
    } catch (error) {
      setStakingLoader(false);
      handleTransactionError(error);
    }
  };

  const handleTransactionError = (error: unknown) => {
    const ethError = error as EthereumError;
    if (ethError.code === 4001 || ethError.code === "ACTION_REJECTED") {
      toast.error("Transaction was rejected by the user!");
      setStakeAmount(0);
    } else {
      console.error("Transaction error:", error);
      toast.error("An error occurred during the transaction");
    }
  };

  const claimReward = async () => {
    try {
      const provider = await web3Modal.connect();
      const web3Provider = new ethers.providers.Web3Provider(provider);
      const signer = web3Provider.getSigner();

      const stakingContract = new ethers.Contract(
        String(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS),
        abi,
        signer
      );

      setRewardLoader(true);
      const claimRewardTx = await stakingContract.claimReward(unStakeID);
      await claimRewardTx.wait();
      setProgress(0);
      setDaysLeft(0);
      setUnStakeID(null);
      setRewardLoader(false);
      getData(signer);
      toast.success("Rewards claimed successfully!");
    } catch (error) {
      setRewardLoader(false);
      handleTransactionError(error);
    }
  };

  const unStake = async () => {
    try {
      const provider = await web3Modal.connect();
      const web3Provider = new ethers.providers.Web3Provider(provider);
      const signer = web3Provider.getSigner();

      const stakingContract = new ethers.Contract(
        String(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS),
        abi,
        signer
      );

      setUnStakeLoader(true);

      const unStakeTx = await stakingContract.withdrawTokens(unStakeID);
      await unStakeTx.wait();
      setProgress(0);
      setDaysLeft(0);
      setUnStakeID(null);
      setUnStakeLoader(false);
      getData(signer);
      toast.success("Tokens unStaked successfully!");
    } catch (error) {
      setUnStakeLoader(false);
      handleTransactionError(error);
    }
  };

  const getData = async (
    signer: ethers.providers.Provider | ethers.Signer | undefined
  ) => {
    // try {
    const stakingContract = new ethers.Contract(
      String(process.env.REACT_APP_STAKING_CONTRACT_ADDRESS),
      abi,
      signer
    );

    await fetchActiveStakes(stakingContract);
    await fetchStakeDetails(stakingContract);
    // } catch (error) {
    //   console.error("Failed to get data:", error);
    // }
  };

  const fetchStakeDetails = async (stakingContract: ethers.Contract) => {
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();

    const stakes = await stakingContract.getUserStakes(address);
    setRawStakes(stakes);
    const obj: {
      totalAmount: ethers.BigNumber;
      totalRewards: ethers.BigNumber;
      days: number;
      remainingDays: number;
    } = {
      totalAmount: ethers.BigNumber.from(0),
      totalRewards: ethers.BigNumber.from(0),
      days: 0,
      remainingDays: 0,
    };
    stakes.map(
      (stake: {
        amount: { toString: () => ethers.BigNumberish };
        stakingDuration: number;
        startTime: number;
        reward: { toString: () => ethers.BigNumberish };
        isRevoked: boolean;
        isClaimed: boolean;
        claimTimestamp?: { toNumber: () => number };
      }) => {
        const stakeAmount = ethers.BigNumber.from(stake.amount.toString());
        const stakeRewards = ethers.BigNumber.from(stake.reward.toString());
        if (!stake.isRevoked && !stake.isClaimed) {
          obj.totalAmount = ethers.BigNumber.from(obj.totalAmount).add(
            stakeAmount
          );

          obj.totalRewards = obj.totalRewards.add(stakeRewards);
          if (obj.days < stake.stakingDuration / (3600 * 24))
            obj.days = stake.stakingDuration / (3600 * 24);
        }
      }
    );

    setLockedToken(
      Number(ethers.utils.formatEther(obj.totalAmount.toString()))
    );

    setLockedDuration(obj.days);

    setTotalReward(
      Number(parseFloat(ethers.utils.formatEther(obj.totalRewards)).toFixed(2))
    );
  };
  const progressBar = (unStakeID: number) => {
    const days = rawStakes[unStakeID].stakingDuration / (3600 * 24);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const elapsedTime = currentTime - (rawStakes[unStakeID].startTime || 0);
    const remainingDays = Math.max(
      0,
      Math.floor(
        (rawStakes[unStakeID].stakingDuration - elapsedTime) / (3600 * 24)
      )
    );
    setDaysLeft(remainingDays);
    const progress = ((days - remainingDays) / days) * 100;
    setProgress(Math.round(progress));
  };
  const fetchActiveStakes = async (stakingContract: any) => {
    const web3Provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();

    const activeStakesData = await stakingContract.getUserStakes(address);

    const formattedActiveStakes = activeStakesData.map(
      (stake: {
        amount: { toString: () => ethers.BigNumberish };
        stakingDuration: number;
        reward: { toString: () => ethers.BigNumberish };
        isRevoked: boolean;
        isClaimed: boolean;
      }) => ({
        // if(stake.isRevoked){}
        isClaimed: stake.isClaimed,
        isRevoked: stake.isRevoked,
        totalAmount: ethers.utils.formatEther(stake.amount.toString()),
        days: stake.stakingDuration / (3600 * 24),
        totalRewards: parseFloat(
          ethers.utils.formatEther(stake.reward.toString())
        ).toFixed(2),
      })
    );
    setActiveStakes(formattedActiveStakes);
  };
  console.log(typeof unStakeID, "unStakeID");
  console.log(unStakeID, "unStakeID");
  const showError = stakeAmount < 1000;
  return (
    <>
      <div className={classes.stakingWrapper}>
        <Toaster position="top-center" reverseOrder={false} />
        <Grid container spacing={1}>
          <Grid item lg={7} md={7} xs={12}>
            <div className="container">
              <div className="row pt-lg-5 pt-md-4 pt-3">
                <div className="col-lg-6 col-md-6 col-12 g-0">
                  <div className="d-flex justify-content-end">
                    {stakingLoader ? (
                      <div className="staking_box_loader mt-lg-5 mt-md-4 mt-3 mb-3">
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <div
                            className="spinner-grow"
                            style={{
                              width: "3rem",
                              height: "3rem",
                              color: "#A7CAED",
                            }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="staking_box mt-lg-5 mt-md-4 mt-3 mb-3">
                        <div className="text-center">
                          <h5 className="stake_with_ideaology_title mb-3">
                            Stake With Ideaology
                          </h5>
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="stakeInput"
                            className="staking_input_fields_label pb-1"
                          >
                            How much you want to stake with us?
                          </label>
                          <br />
                          <input
                            type="number"
                            id="stakeInput"
                            name="stakeInput"
                            placeholder="Min 1000 IDEA Token"
                            className="w-100 staking_input_fields"
                            value={stakeAmount}
                            onChange={handleStakeInputChange}
                            min="1000"
                            // disabled
                          />
                          {showError && (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              Value must be at least 1000 IDEA Token
                            </p>
                          )}
                        </div>
                        <div>
                          <p className="staking_input_fields_label mb-1">
                            Select preferable time period for staking?
                          </p>
                          <Tabs
                            id="controlled-tab-example"
                            activeKey={selectedKey}
                            onSelect={handleSelect}
                            className="mb-3"
                          >
                            <Tab eventKey="30days" title="30 Days"></Tab>
                            <Tab eventKey="90days" title="90 Days"></Tab>
                            <Tab eventKey="180days" title="180 Days"></Tab>
                            {/* <Tab
                              eventKey="365days"
                              title="365 Days"
                              disabled
                            ></Tab> */}
                          </Tabs>
                        </div>
                        <div>
                          <label
                            htmlFor="rewardInput"
                            className="staking_input_fields_label pb-1"
                          >
                            APY ({rewardPercentage}%)
                          </label>
                          <br />
                          <input
                            type="text"
                            id="rewardInput"
                            name="rewardInput"
                            className="w-100 staking_input_fields"
                            value={reward}
                            disabled
                          />
                        </div>
                        <div className="pt-3">
                          <button
                            className={`w-100 start_staking_btn ${
                              stakeAmount == 0 ? "start_staking_disable" : ""
                            } `}
                            disabled={stakeAmount == 0}
                            onClick={
                              // handleShow
                              createStake
                            }
                            // disabled
                          >
                            Start Staking
                            {/*move to staking page */}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="d-flex justify-content-start">
                    <div className="staking_progress_box ms-2">
                      {/* {rewardLoader ? <LoadingScreen /> : <></>} */}
                      <div className="text-center">
                        <h5 className="stake_with_ideaology_title mb-lg-3 mb-md-3 mb-3">
                          Upcoming Stake
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0 locking_amount">Locked Amount</p>
                        <p className="mb-0 loading_amount_token">
                          {lockedToken} IDEA
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="mb-0 locking_amount">Unlock Days</p>
                        <p className="mb-0 loading_amount_token">
                          {lockedDuration} Days
                          {/* {lockedDuration}  */}
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="mb-0 locking_amount">
                          Total Tokens to Claim
                        </p>
                        <p className="mb-0 loading_amount_token">
                          {totalReward} IDEA
                        </p>
                      </div>
                      <div>
                        <div className="border_top_bottom_stake py-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0 progress_amount_token">
                              Progress
                            </p>
                            <p className="mb-0 progress_amount_token">
                              {isNaN(progress) ? 0 : progress}
                              {/* 0% */}
                            </p>
                          </div>
                          <div className="py-2">
                            <Box sx={{ width: "100%" }}>
                              <LinearProgressWithLabel value={progress || 0} />
                            </Box>
                          </div>
                          <div>
                            <p className="mb-0 locking_amount">
                              Claimable in {daysLeft} Days
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="pt-3">
                        <div className="d-flex align-items-center justify-content-between">
                          {isWalletConnected ? (
                            <></>
                          ) : (
                            <button
                              className="w-100 claim_staking_btn"
                              // onClick={handleShow}
                              onClick={connectWallet}
                              // disabled
                            >
                              Connect
                            </button>
                          )}
                          {unStakeID != null && (
                            <>
                              {daysLeft == 0 ? (
                                <button
                                  className="w-100 claim_staking_btn "
                                  // disabled={
                                  //   !isWalletConnected ||
                                  //   activeStakes?.length != 0 ||
                                  //   !unStakeID
                                  // }
                                  // disabled
                                  onClick={claimReward}
                                  // onClick={handleShow}
                                >
                                  Claim Staking
                                </button>
                              ) : (
                                <button
                                  className="w-100 claim_staking_btn ms-3"
                                  // disabled={!isWalletConnected || lockedToken === 0}
                                  onClick={unStake}
                                  // onClick={handleShow}
                                  // disabled
                                >
                                  Unstake
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-start mt-lg-4 mt-md-3 mt-3">
                    {/* {activeStakes.length > 0 && isWalletConnected ? ( */}
                    {activeStakes && activeStakes?.length > 0 ? (
                      <div className="staking_progress_box ms-2">
                        <div className="text-center">
                          <h5 className="stake_with_ideaology_title mb-lg-3 mb-md-3 mb-3">
                            My Stakes
                          </h5>
                        </div>
                        <div className="table_overflow">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col" className="table_heading_th">
                                  Amount
                                </th>
                                <th scope="col" className="table_heading_th">
                                  Days
                                </th>
                                <th scope="col" className="table_heading_th">
                                  Reward
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {activeStakes?.map((stake, index) => {
                                return (
                                  !stake?.isRevoked &&
                                  !stake?.isClaimed && (
                                    <tr key={index}>
                                      <td className="table_data_td">
                                        {stake?.totalAmount}
                                      </td>
                                      <td className="table_data_td">
                                        {stake?.days}
                                      </td>
                                      <td className="table_data_td">
                                        {stake.totalRewards} IDEA
                                      </td>
                                      <input
                                        className="table_data_td"
                                        type="radio"
                                        name="address"
                                        // value={result.ADDRESS}
                                        // checked={
                                        //   this.state.address === result.ADDRESS
                                        // }
                                        onChange={() =>
                                          handleRadioChange(index)
                                        }
                                      />
                                    </tr>
                                  )
                                );
                                // );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="staking_progress_box ms-2">
                        <div className="text-center">
                          <h5 className="stake_with_ideaology_title mb-lg-3 mb-md-3 mb-3">
                            No Active Stakes
                          </h5>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={5} xs={12}>
            <div className={classes.stakingCard}>
              <h1>Staking</h1>
              <p>
                Staking is the process of delegating or locking up crypto
                holdings to earn rewards. By staking their IDEA tokens, users
                not only contribute to the ecosystem&apos;s growth but also
                benefit from attractive APY rewards based on the duration of
                their stake.
              </p>
              <p>
                The primary benefit of staking is that you earn more crypto, and
                interest rates can be very generous. It&#39;s potentially a very
                profitable way to invest your money.
              </p>
              <div className={classes.note}>
                <Button
                  className={classes.noteItem}
                  onClick={() =>
                    history.push({
                      pathname: "/staking-vesting",
                      state: { from: "staking" },
                    })
                  }
                >
                  Read More on Benefits & Rules{" "}
                  <img
                    alt="icon"
                    src={"imgs/landing/moredetails.svg"}
                    style={{ marginLeft: "10px" }}
                    loading="lazy"
                    width="20px"
                    height="20px"
                  />
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* <Modal
        show={show}
        onHide={handleClose}
        centered
        style={{ borderRadius: "50px" }}
      >
        <div className="inner_modal text-center">
          <div className="lottie">
            <Lottie
              animationData={maintenance}
              loop={true}
              style={{ width: "220px", marginLeft: "105px" }}
            />
          </div>
          <p className="inner_modal_text mt-3">
            Our staking platform is currently under maintenance. We&apos;ll be
            back soon with the same APY rates and even more rewards! Thanks for
            your patience! 🙌
          </p>
        </div>
      </Modal> */}
    </>
  );
}
